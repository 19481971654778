.tabContainer {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  .searchForm {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    height: 65px;
    transition: 300ms;
    z-index: 1;

    .blueBack {
      position: absolute;
      top: 32.5px;
      left: 30px;
    }

    .searchInput {
      margin: 20px !important;
      position: absolute;
      margin: 0 auto;
      width: 100%;
      height: 45px;
      padding: 0 20px;
      font-size: 1rem;
      border: 1px solid #d0cfce;
      outline: none;
      border-radius: 5px;
      background-color: #fff;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      span {
        margin-bottom: 2px;
      }

      input {
        outline: none;
        font-size: 1rem;
        border: none;
        background-color: transparent;
      }
    }

    .searchInput2 {
      margin: 20px !important;
      position: absolute;
      margin: 0 auto;
      width: 100%;
      height: 45px;
      top: 65px;
      background: #ffffff8f;
      padding: 0 20px;
      font-size: 1rem;
      border: 1px solid #d0cfce;
      outline: none;
      border-radius: 5px;
      display: flex;
      gap: 0.5rem;
      align-items: center;

      span {
        margin-bottom: 2px;
      }

      input {
        width: 75%;
        outline: none;
        font-size: 1rem;
        border: none;
        background-color: transparent;
        // margin-left: 2.5rem;
      }
    }

    .searchClose2 {
      position: absolute;
      height: 25px;
      width: 25px;
      right: -50px;
      top: 95px;
      cursor: pointer;
    }

    .searchIcon {
      position: absolute;
      width: 75px;
      right: -75px;
      height: 75px;
      top: 5px;
      pointer-events: none;
    }

    .searchClose {
      position: absolute;
      height: 25px;
      width: 25px;
      right: -50px;
      top: 30px;
      cursor: pointer;
    }

    .populatedList {
      z-index: 2;
      overflow: auto;
      background-color: white;
      position: absolute;
      width: calc(100% + 40px);
      max-height: calc(90vh - 10rem);
      margin: 20px;
      // padding: 20px;
      top: 70px;
      border-radius: 5px;
      transition: 500ms;
      opacity: 1 !important;
      pointer-events: all !important;

      .listChild {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 1rem;
        border-radius: 5px;

        .exhibhitorDesc {
          font-size: 1.2rem;
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: flex-start;

          .exhibitorSubDesc {
            font-size: 1.2rem;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          }

          .catalogBrief {
            font-size: 1.2rem;
            color: grey;
            max-height: 6vh;
            overflow: hidden;
            transition: 500ms;
          }
        }
      }

      .btnsWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        z-index: 2;
        margin-left: 20%;
        margin-right: 10%;

        >img {
          width: 6rem;
          margin: 0.5rem 0;
        }

        &> :last-child {
          width: 8rem;
        }
      }

      .itemViewMore {
        width: 100%;
        overflow: auto;
        max-height: 70vh;

        .detailInfoStickyHeader {
          // position: absolute;
          // top: 10;
          display: flex;
          width: 100%;
          padding: 0.5rem;
          // margin-right: 1rem;
          box-sizing: border-box;
          align-items: center;
          justify-content: space-between;

          >img {
            width: 4rem;
          }

          .rightBtns {
            display: flex;
            align-items: center;
            gap: 1rem;

            >img {
              width: 8rem;
            }

            .shareBtn {
              width: 1rem;
              height: 1rem;
              background-color: #fff;
              border-radius: 50%;
              padding: 0.25rem;
              object-fit: contain;
            }
          }
        }

        >img {
          width: 100%;
        }

        .infoContainer {
          padding: 0.5rem 1rem;

          .title {
            font-size: 2.5vh;
            font-weight: 500;
          }

          .extraInfoCatalog {
            margin-top: 3rem;
            margin-bottom: 1rem;
          }

          table td:first-child {
            min-width: 5rem;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
          }

          table td {
            
          }
        }

        &::-webkit-scrollbar {
          width: 0.5rem;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 8px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
    }
  }

  .modalOverlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .shareModal {
    position: fixed;
    display: flex;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    padding: 2rem 3rem;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    min-width: 20rem;
    background-color: #1d292c;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
    align-items: center;
    z-index: 12;

    .close {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    .title {
      color: #fff;
      font-size: 1.5rem;
      font-weight: 600;
    }

    .canvas {
      width: 15rem !important;
      height: 15rem !important;
      margin: 0 5rem;
    }

    .linkWrapper {
      border: 1px solid #fff;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;

      .link {
        width: 60%;
      }
    }

    .socialHandles {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-evenly;
    }
  }

  .filtersDiv {
    .btn {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      opacity: 0.9;
      transition: 200ms;

      &:hover {
        transform: scale(1.15);
        transition: 500ms;
        cursor: pointer;
      }
    }

    display: flex;
    align-items: center;
    position: absolute;
    left: calc(20rem + 120px);
    top: 23px;
    width: 76vw;
    pointer-events: all;
    -ms-overflow-style: none;
    scrollbar-width: none;
    flex-wrap: wrap;
    max-width: 50vw;
    gap: 1rem;
    padding-left: 4rem;

    .filtersTab {
      background-color: white;
      color: black;
      border-radius: 20px;
      padding: 1vh 2vh;
      margin: 0vh 1vh;
      white-space: nowrap;

      &:hover {
        transform: scale(1.15);
        transition: 500ms;
        cursor: pointer;
      }
    }
  }

  .foodmenuOverlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9;
  }

  .foodMenuWrapper {
    position: absolute;
    top: 5rem;
    left: 20px;
    padding: 2rem;
    background-image: url("../../assets/images/Group.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #ffff;
    border-radius: 8px;
    z-index: 10;

    .header {
      font-weight: 600;
      text-align: center;
      font-size: 1.15rem;
    }

    table {
      border-collapse: collapse;
    }

    table,
    td,
    th {
      // padding: 0.5rem;
      // border: 1px solid;
    }
  }
}

.foodMenuWrapper::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
.foodMenuWrapper::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.foodMenuWrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/* Handle on hover */
.foodMenuWrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (max-width: 1100px) {
  .tabContainer {
    .searchForm {
      .searchInput2 {
        background: #989898;
      }
    }

    .filtersDiv {
      align-items: center;
      position: fixed;
      left: 7px;
      top: 6.2rem;
      height: 3rem;
      width: auto;
      max-width: 93%;
      flex-wrap: nowrap;
      overflow: auto;
      gap: 0;
      padding-left: 0;
      box-sizing: border-box;
      padding-right: 10rem;

      .btn {
        position: relative;
        width: 2.6rem;
      }

      .filtersTab {
        margin: 0vh 0.8rem;
        padding: 0.5rem 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .tabContainer {
    .searchForm {
      position: fixed;
      bottom: 0;
      left: 0;
      margin: 0;
      height: 45px;
      transition: 300ms;
      margin-bottom: 0.5rem;
      width: 100% !important;
      top: unset;

      .blueBack {
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translate(-50%, -50%);
      }

      .searchInput {
        margin: 0 !important;
        position: absolute;
        margin: 0 auto;
        width: 100% !important;
        height: 45px;
        padding: 0 10px;
        font-size: 1rem;
        border: 1px solid #d0cfce;
        outline: none;
        border-radius: 5px;
        box-sizing: border-box;
      }

      .searchInput2 {
        margin: 0 !important;
        padding: 0px 20px 0px 50px;
        box-sizing: border-box;
        width: 100% !important;
      }

      .searchClose {
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
      }

      .searchIcon {
        position: absolute;
        z-index: 6;
        width: auto;
        right: 0;
        height: 45px;
        top: 0;
        pointer-events: none;
      }

      .populatedList {
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0%;
        z-index: 55;
        height: 0%;
        top: unset;
        margin: 0;
        justify-content: center;
        transition: 300ms;
      }

      .emptyList {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: gray;
      }
    }

    .filtersDiv {
      align-items: center;
      position: fixed;
      left: 50px;
      top: 0;
      height: 9vh;
      width: auto;
      max-width: 90%;
      flex-wrap: nowrap;
      overflow: auto;
      gap: 0;
      padding-left: 0;
      box-sizing: border-box;
      padding-right: 10rem;

      .btn {
        position: relative;
      }

      .filtersTab {
        margin: 0vh 1vh;
      }
    }

    .foodMenuWrapper {
      width: 100%;
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 15vh;
      left: 0;
      padding: 2rem;
      box-sizing: border-box;
      height: 70vh;
    }
  }
}