
@media screen and (max-width: 768px) {
    .carousel-root{
        max-width: 80%;
        /* background-color: red; */
        pointer-events: none;
    }
    .carousel-root button{
        pointer-events: auto;
    }
    .carousel .thumb{
        pointer-events: auto;
        max-width: 3rem;
    }
    
}