.loading {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  place-content: center;
  z-index: 10;
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    &::after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid #015189;
      border-color: #015189 transparent #015189 transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }
}

.logo{

}

@media screen and (max-width: 768px) {
  
  .logo{
    display: none;
  }
}

.dropdown {
  position: fixed;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  top: 22.5px;
  background-color: #fff;
  left: calc(20rem + 80px);
  cursor: pointer;
  transition: 200ms;
  border-radius: 50%;
  z-index: 2;
  display: grid;
  place-content: center;
  &:hover {
    transform: scale(1.1);
  }
  .filterIcon {
    width: 0.75rem;
    height: 0.75rem;
    position: absolute;
    top: -0.05rem;
    right: -0.05rem;
    background-color: red;
    border-radius: 50%;
  }
  .dropdownSvg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
}

.switchScenes {
  display: flex;
  align-items: center;
  position: fixed;
  // bottom: 10px;
  // left: 10px;
  top: 6rem;
  right: 0;
  border-radius: 20px;
  z-index: 2;
  > div {
    cursor: pointer;
    box-sizing: border-box;
    padding: 1.3rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #fff;
    &:hover {
      transform: scale(1.1);
      transition: 500ms;
    }
  }
}
.modalOverlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.24);
  z-index: 9;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10;
  max-height: 50vh;
  overflow: auto;
  background-color: #fff;
  width: 35vw;
  display: flex;
  flex-direction: column;
  transform: translate(-50%, -50%);
  padding: 2rem 5rem;
  border-radius: 10px;
  .checkboxWrapper {
    padding: 1rem 0;
    display: flex;
    align-items: center;
    gap: 2rem;
  }
  .headname {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    margin: 1.5rem 0;
  }
  .apply {
    padding: 1rem;
    background-color: #000;
    border: 1px solid #fff;
    color: #fff;
    width: 10rem;
    margin: 0 auto;
    margin-top: 2rem;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    &:hover {
      background-color: #fff;
      border: 1px solid #000;
      color: #000;
    }
  }
}

@media screen and (max-width: 1100px) {
  .switchScenes {
    // bottom: unset;
    // top: 6rem;
    // left: unset;
    // right: 0;
    > div {
      padding: 0.95rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .dropdown {
    top: unset;
    top: 10px;
    left: 10px;
  }

  .switchScenes {
    bottom: unset;
    top: 70px;
    left: unset;
    right: 10px;
    > div {
      padding: 0.6rem;
    }
  }

  .modal {
    width: 70vw;
    padding: 2rem;
    .checkboxWrapper {
      padding: 1rem 0;
      display: flex;
      align-items: center;
      gap: 2rem;
    }
    .headname {
      font-weight: 600;
      font-size: 20px;
      text-align: center;
      margin: 1.5rem 0;
    }
    .apply {
      padding: 1rem;
      background-color: #000;
      border: 1px solid #fff;
      color: #fff;
      width: 10rem;
      margin: 0 auto;
      margin-top: 2rem;
      border: none;
      border-radius: 20px;
      cursor: pointer;
      &:hover {
        background-color: #fff;
        border: 1px solid #000;
        color: #000;
      }
    }
  }
}
