.img {
    max-height: 20rem;
    object-fit: contain;

    @media screen and (max-width: 768px) {
        max-height: 10rem;
    }
}

.imageContainer{
    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: center;
    }
}